import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { KnowledgeBase } from '../../interfaces/knowledge-base/knowledge-base';
import { KnowledgeBaseArticle } from '../../interfaces/knowledge-base/knowledge-base-article';
import { FrequentlyAskedQuestions } from '../../interfaces/knowledge-base/frequently-asked-questions';
import { SupportContactDetails } from '../../interfaces/knowledge-base/support-contact-details';
import { Changelog } from '../../interfaces/configuration/changelog';
import { ChangelogArticle } from '../../interfaces/configuration/changelog-article';
import { KnowledgeBaseOrganisation } from '../../interfaces/knowledge-base/knowledge-base-organisation';

@Injectable()

export class PublicKnowledgeBaseService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<KnowledgeBase[] | boolean> {
    return new Observable<KnowledgeBase[] | boolean>((o: Subscriber<KnowledgeBase[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.kb.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<KnowledgeBase[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(url: string): Observable<KnowledgeBase | boolean> {
    return new Observable<KnowledgeBase | boolean>((o: Subscriber<KnowledgeBase | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.kb.read', {':url': url}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<KnowledgeBase>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  articles(url: string): Observable<ServerResponse> {
    return new Observable<ServerResponse>((o: Subscriber<ServerResponse>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.kb.articles', {':articles': url}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next();
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  article(articlesUrl: string, articleUrl: string): Observable<KnowledgeBaseArticle> {
    return new Observable<KnowledgeBaseArticle>((o: Subscriber<KnowledgeBaseArticle>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.kb.article', {':articles': articlesUrl, ':article': articleUrl}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<KnowledgeBaseArticle>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisations(): Observable<KnowledgeBaseOrganisation[]> {
    return new Observable<KnowledgeBaseOrganisation[]>((o: Subscriber<KnowledgeBaseOrganisation[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.organisations'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<KnowledgeBaseOrganisation[]>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  allFaq(): Observable<FrequentlyAskedQuestions[]> {
    return new Observable<FrequentlyAskedQuestions[]>((o: Subscriber<FrequentlyAskedQuestions[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.faq.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<FrequentlyAskedQuestions[]>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  allChangelogs(): Observable<Changelog[]> {
    return new Observable<Changelog[]>((o: Subscriber<Changelog[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.changelogs.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Changelog[]>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  changelog(url: string): Observable<Changelog> {
    return new Observable<Changelog>((o: Subscriber<Changelog>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.changelogs.read', {':url': url}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Changelog>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  allChangelogArticles(url: string): Observable<ChangelogArticle[]> {
    return new Observable<ChangelogArticle[]>((o: Subscriber<ChangelogArticle[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('knowledge-base.changelogs.articles', {':changelog_url': url}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ChangelogArticle[]>response.data);
          }
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  supportRequest(formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('knowledge-base.support-request'), formData);
  }
}
