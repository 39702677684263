import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '../../../../@fury/shared/material-components.module';
import { ScrollbarModule } from '../../../../@fury/shared/scrollbar/scrollbar.module';
import { EditorComponent } from './editor.component';
import { LoadingOverlayModule } from '../../../../@fury/shared/loading-overlay/loading-overlay.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuryAlertBoxModule } from '../../../../@fury/shared/alert-box/alert-box.module';
import { MtxDatetimepickerModule } from '@ng-matero/extensions/datetimepicker';
import { LightboxModule } from 'ng-gallery/lightbox';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { RouterModule } from '@angular/router';
import { PageHeaderButtonsModule } from '../../../../@fury/shared/page-header-buttons/page-header-buttons.module';
import { TableFiltersModule } from '../../../../@fury/shared/table-filters/table-filters/table-filters.module';
import { MatChipsModule } from '@angular/material/chips';
import { RemovableCategoryComponent } from './removable-category/removable-category.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ScrollbarModule,
    LoadingOverlayModule,
    ReactiveFormsModule,
    FuryAlertBoxModule,
    MtxDatetimepickerModule,
    LightboxModule,
    LightboxModule,
    MaterialFileInputModule,
    RouterModule,
    PageHeaderButtonsModule,
    FormsModule,
    TableFiltersModule,
    MatChipsModule,
  ],
  declarations: [
    EditorComponent,
    RemovableCategoryComponent,
  ],
  exports: [
    EditorComponent,
    RemovableCategoryComponent,
  ]
})
export class EditorModule {
}
