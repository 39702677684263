import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../interfaces/routering/user';

@Injectable()

export class AuthorizationService implements CanActivate, CanActivateChild {
  constructor(private router: Router,
              private titleService: Title,
              private authenticationService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.user$.pipe(
      map((user: User): boolean => {
        return this.allowAccess(user, state, next);
      })
    );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.user$.pipe(
      map((user: User): boolean => {
        return this.allowAccess(user, state, next);
      })
    );
  }

  allowAccess(user: User, state: RouterStateSnapshot, next: ActivatedRouteSnapshot): boolean {
    if (user) {
      if (user.role === 'super-admin' && state.url.indexOf('klanten') === -1) {
        this.router.navigateByUrl('/klanten').then((): void => {
        });
        return true;
      } else {
        if (typeof user.user_agreement_confirmed !== 'undefined' &&
          !user.user_agreement_confirmed &&
          (typeof user.role === 'undefined' || user.role !== 'super-admin')) {
          if (state.url.indexOf('/auth/user-agreement') === -1) {
            this.router.navigate(['/auth/user-agreement']).then((): void => {
            });
            return false;
          } else {
            return true;
          }
        } else if (typeof user.two_factor_enabled !== 'undefined' &&
          !user.two_factor_enabled &&
          (typeof user.role === 'undefined' || user.role !== 'super-admin')) {
          if (state.url.indexOf('/auth/account/two-factor-authentication') === -1) {
            this.router.navigate([`/auth/account/two-factor-authentication`]).then((): void => {
            });
            return false;
          } else {
            return true;
          }
        } else {
          const permissions: string[] = next.data['permissions'] as Array<string>,
            title: string = next.data['title'] + ' | ' + environment.webappName;

          if (user.role === 'super-admin') {
            this.titleService.setTitle(title);
            return true;
          }

          if (typeof permissions !== 'undefined') {
            if (permissions.length && user.permissions && user.permissions.length) {
              if (permissions.some((permission: string) => user.permissions.includes(permission))) {
                this.titleService.setTitle(title);
                return true;
              } else {
                this.router.navigate([`/handleiding`]).then((): void => {
                });
                return false;
              }
            } else {
              this.titleService.setTitle(title);
              return true;
            }
          } else {
            this.titleService.setTitle(title);
            return true;
          }
        }
      }
    } else {
      this.router.navigate([`/auth/login`]).then((): void => {
      });
      return false;
    }
  }
}
