import { Component, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from '../../services/base/authentication.service';

@Component({
  selector: 'fury-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {

  @Output() openSidenav: EventEmitter<any> = new EventEmitter();

  constructor(public authenticationService: AuthenticationService) {
  }
}
