<div (furyClickOutside)="onClickOutside()" class="toolbar-user" fxFlex="grow">
  <button (click)="toggleDropdown()" [class.open]="isOpen" class="button" fxFlex="grow" mat-button>
    <span fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>account_circle</mat-icon>
      <span class="name" fxHide fxShow.gt-sm>{{ (authenticationService.user$ | async)?.name }}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div [class.open]="isOpen" class="dropdown">
    <div class="content">
      <div class="list">
        <div routerLink="/auth/account" class="list-item" matRipple>
          <div>
            <mat-icon class="list-item-icon">account_circle</mat-icon>
            <span>Profiel</span>
          </div>
        </div>
        <div routerLink="/auth/account/two-factor-authentication" class="list-item" matRipple>
          <div>
            <mat-icon class="list-item-icon">lock_open</mat-icon>
            <span>Twee-factor authenticatie</span>
          </div>
        </div>
        <div (click)="stopImpersonating()"
             *ngIf="(authenticationService.user$ | async)?.impersonated_by" class="list-item"
             matRipple>
          <div>
            <mat-icon class="list-item-icon">clear</mat-icon>
            <span>Stop impersonating user</span>
          </div>
          <mat-spinner *ngIf="isLoadingImpersonation" diameter="24"></mat-spinner>
        </div>
        <div *ngIf="!(authenticationService.user$ | async)?.impersonated_by"
             routerLink="/auth/account/change-password" class="list-item" matRipple>
          <div>
            <mat-icon class="list-item-icon">settings</mat-icon>
            <span>Wachtwoord aanpassen</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div routerLink="/handleiding" class="list-item" matRipple>
          <div>
            <mat-icon class="list-item-icon">help</mat-icon>
            <span>Help & support</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div (click)="captureBug()" class="list-item marker-report-a-bug" matRipple>
          <div>
            <mat-icon class="list-item-icon">bug_report</mat-icon>
            <span>Een probleem melden</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div (click)="logout()" class="list-item" matRipple>
          <div>
            <mat-icon class="list-item-icon">exit_to_app</mat-icon>
            <span>Logout</span>
          </div>
          <mat-spinner *ngIf="isLoadingLogout" diameter="24"></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</div>
