import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { MonthlyStatistic } from '../../interfaces/statistics/monthly-statistic';

@Injectable()

export class StatisticsService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  categoriesPerMunicipality(): Observable<MonthlyStatistic | boolean> {
    return new Observable<MonthlyStatistic | boolean>((o: Subscriber<MonthlyStatistic | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.categories-per-municipality'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MonthlyStatistic>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportCategoriesPerMunicipality(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.categories-per-municipality-download'));
  }

  complaintsPerCompany(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.complaints-per-company'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportComplaintsPerCompany(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.complaints-per-company-download'));
  }

  totalComplaintsPerCompany(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.total-complaints-per-company'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportTotalComplaintsPerCompany(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.total-complaints-per-company-download'));
  }

  totalComplaintsPerCompanyForCompany(id: number): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.total-complaints-per-company.for-company.index', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportTotalComplaintsPerCompanyForCompany(id: number): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.total-complaints-per-company.for-company.download', {':id': id}));
  }
}
