import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidenavItem } from './sidenav-item/sidenav-item.interface';
import { SidenavService } from './sidenav.service';
import { AuthenticationService } from '../../services/base/authentication.service';
import { version } from '../../../environments/version';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  public version = version + environment.appVersionAddon;
  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  items$: Observable<SidenavItem[]>;
  itemsBottom$: Observable<SidenavItem[]>;

  constructor(public authenticationService: AuthenticationService,
              private sidenavService: SidenavService,
  ) {
  }

  ngOnInit(): void {
    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );

    this.itemsBottom$ = this.sidenavService.itemsBottom$.pipe(
      map((items: SidenavItem[]) => this.sidenavService.sortRecursive(items, 'position'))
    );
  }

  ngOnDestroy(): void {
  }
}
