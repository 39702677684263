import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from '../services/base/local-storage.service';
import { ApiService } from '../services/base/api.service';

@Injectable()

export class AddTokenInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService,
              private api: ApiService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.localStorageService.get('api-token');

    if (token !== null && (
      request.url.indexOf('https://maps.googleapis.com') === -1 &&
      request.url.indexOf('https://api.pdok.nl') === -1
    )) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.api.authenticationError.next(true);
          }
        }
        return throwError(error);
      })
    );
  }
}
