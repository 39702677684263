import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as FileSaver from 'file-saver';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { SnackbarService } from './snackbar.service';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ServerResponse } from '../../interfaces/base/server.response';
import { catchError, map, switchMap } from 'rxjs/operators';
import { FormService } from './form.service';
import { ServerRouteProperties } from '../../interfaces/base/server-route-properties';
import { NavigationEnd, Router, UrlSerializer, UrlTree } from '@angular/router';
import * as _ from 'lodash';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../../../@fury/shared/dialog/delete-dialog/delete-dialog.component';
import { FileInput } from 'ngx-material-file-input';
import { FilterValue } from '../../interfaces/base/filter-value';
import { LocalStorageService } from './local-storage.service';

@Injectable()

export class ApiService {

  public authenticationError: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public generalHttpError: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public tableResetButtonVisible: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public recheckTableResetButtonVisibility: EventEmitter<boolean> = new EventEmitter<boolean>();

  public preSetFilters: ReplaySubject<FilterValue[]> = new ReplaySubject<FilterValue[]>(1);
  public filterUpdated: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public filterCleared: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  public clearAllFilters: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  public pageSize: number = 20;
  public pageSizeOptions: number[] = [10, 20, 50, 100, 200];
  public length: number = 0;
  public routeParams: ServerRouteProperties = {
    active: '',
    direction: 'asc',
    page: 1,
    length: this.pageSize,
    filters: [],
  };

  public matSort: MatSort = null;
  public matPaginator: MatPaginator = null;
  private defaultPageSize: number = 20;
  public queryString: string = null;
  public filterPageName: string = null;

  private ignoreFormFields: string[] = [
    'attachmentsContainer',
    'hasCategories',
    'categoriesContainer',
  ];

  constructor(private http: HttpClient,
              private snackbarService: SnackbarService,
              private formService: FormService,
              private router: Router,
              private serializer: UrlSerializer,
              private dialog: MatDialog,
              private localStorageService: LocalStorageService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.clearPagination();
        this.clearFilter(null, false);
        this.clearSort(false);

        this.toggleResetTableButton();
      }
    });

    this.recheckTableResetButtonVisibility.subscribe((): void => this.toggleResetTableButton());

    this.filterUpdated.subscribe((): void => this.toggleResetTableButton());

    this.filterCleared.subscribe((): void => {
      this.toggleResetTableButton();
      this.clearPagination();
      this.clearFilter(null, false);
      this.clearSort();
    });

    this.clearAllFilters.subscribe((): void => {
      this.localStorageService.clearFilters(this.filterPageName);

      this.toggleResetTableButton();
      this.clearPagination();
      this.clearFilter(null, false);
      this.clearSort();
    });
  }

  get isOnline(): boolean {
    return !!window.navigator.onLine;
  }

  get(
    url: string,
    urlData?: ServerRouteProperties,
    skipQueryParams: boolean = false
  ): Observable<ServerResponse> {
    if (this.isOnline) {
      this.hasFilters(this.filterPageName);
      return this.http.get(environment.api_endpoint + url + (!skipQueryParams ? this.buildGetUrl(urlData) : ''), {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  post(url: string, formData?: any): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.post(environment.api_endpoint + url, (formData ? this.processFormData(formData) : null), {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  sort(url: string, formData?: any): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.post(environment.api_endpoint + url, formData, {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  postUpload(url: string, formData?: any): Promise<ServerResponse> {
    if (this.isOnline) {
      return new Promise((resolve, reject): void => {
        this.http.post(environment.api_endpoint + url, (formData ? this.processFormData(formData) : null), {
          observe: 'response',
          responseType: 'json',
        })
          .subscribe({
            next: (response: HttpResponse<ServerResponse>) => resolve(this.handleResponse(response)),
            error: (error: HttpErrorResponse) => reject(this.handleErrorResponse(error))
          });
      });
    } else {
      this.notAvailableOffline();
      return new Promise((resolve, reject) => reject());
    }
  }

  put(url: string, formData: any): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.put(environment.api_endpoint + url, this.processFormData(formData), {
        observe: 'response',
        responseType: 'json',
      })
        .pipe(
          map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  delete(url: string, withPrompt: boolean = true): Observable<ServerResponse> {
    if (this.isOnline) {
      if (withPrompt) {
        let dialogRef: MatDialogRef<DeleteDialogComponent>;
        dialogRef = this.dialog.open(DeleteDialogComponent, {
          data: {
            text: 'Geselecteerde item verwijderd?'
          },
          disableClose: true
        });

        return dialogRef.afterClosed()
          .pipe(
            switchMap(result => {
              if (result) {
                return this.http.delete(environment.api_endpoint + url, {
                  observe: 'response',
                  responseType: 'json',
                })
                  .pipe(
                    map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
                    catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
                  );
              } else {
                return of(null);
              }
            })
          );
      } else {
        return this.http.delete(environment.api_endpoint + url, {
          observe: 'response',
          responseType: 'json',
        })
          .pipe(
            map((response: HttpResponse<ServerResponse>) => this.handleResponse(response)),
            catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
          );
      }
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  download(url: string, formData: any = null, filename: string = null, mimeType: string = 'application/octet-stream'): void {
    if (this.isOnline) {
      this.http.get<Blob>(environment.api_endpoint + url + (formData ? this.buildGetUrl(formData) : ''), {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
        .subscribe(
          (response: HttpResponse<Blob>) => this.handleFileDownload(response, filename, mimeType),
          (error: HttpErrorResponse) => this.handleErrorResponse(error)
        );
    } else {
      this.notAvailableOffline();
    }
  }

  export(url: string, urlData?: ServerRouteProperties, skipQueryParams: boolean = false, filename: string = null, mimeType: string = 'application/octet-stream'): Observable<ServerResponse> {
    if (this.isOnline) {
      return this.http.get<Blob>(environment.api_endpoint + url + (!skipQueryParams ? this.buildGetUrl(urlData) : ''), {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
        .pipe(
          map((response: HttpResponse<Blob>) => this.handleFileDownload(response, filename, mimeType)),
          catchError((error: HttpErrorResponse) => this.handleErrorResponse(error))
        );
    } else {
      this.notAvailableOffline();
      return of();
    }
  }

  postDownload(url: string, formData: any = null, filename: string = null, mimeType: string = 'application/octet-stream'): void {
    if (this.isOnline) {
      this.http.post(environment.api_endpoint + url, (formData ? this.processFormData({
        ...formData,
        ...{
          active: (typeof this.matSort !== 'undefined' && this.matSort !== null ? this.matSort.active : null),
          direction: (typeof this.matSort !== 'undefined' && this.matSort !== null ? this.matSort.direction : null),
          page: (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null ? this.matPaginator.pageIndex + 1 : null),
          length: (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null ? this.matPaginator.pageSize : null)
        }
      }) : null), {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
        .subscribe(
          (response: HttpResponse<Blob>) => this.handleFileDownload(response, filename, mimeType),
          (error: HttpErrorResponse) => this.handleErrorResponse(error)
        );
    } else {
      this.notAvailableOffline();
    }
  }

  buildGetUrl(urlData: ServerRouteProperties): string {
    this.queryString = '';
    const filters = this.routeParams.filters,
      routeParams: ServerRouteProperties = {
        active: '',
        direction: '',
        page: 1,
        length: 1,
        filters: []
      },
      skipAttributes: string[] = [
        'causer_search_name'
      ];

    routeParams.active = this.routeParams.active;
    routeParams.direction = this.routeParams.direction;
    routeParams.page = this.routeParams.page;
    routeParams.length = this.routeParams.length;

    delete routeParams.filters;

    if (typeof urlData !== 'undefined') {
      if (urlData !== null) {
        for (const key in urlData) {
          if (urlData.hasOwnProperty(key) && skipAttributes.indexOf(key) === -1) {
            routeParams[key] = urlData[key];
          }
        }
      }
    }

    const tree: UrlTree = this.router.createUrlTree([''], {queryParams: routeParams});
    this.queryString = this.serializer.serialize(tree).substring(1);

    _.forEach(filters, filter => {
      if (skipAttributes.indexOf(filter.field) === -1) {
        if (_.isArray(filter.value)) {
          _.forEach(filter.value, value => {
            this.queryString += (this.queryString.length ? '&' : '?') + 'filter[' + filter.field + '][]=' + value;
          });
        } else {
          this.queryString += (this.queryString.length ? '&' : '?') + 'filter[' + filter.field + ']=' + filter.value;
        }
      }
    });

    return this.queryString;
  }

  handleResponse(res: HttpResponse<ServerResponse>): ServerResponse {
    this.generalHttpError.next(false);
    if (typeof res.body !== 'undefined') {
      if (typeof res.body.message !== 'undefined') {
        this.snackbarService.success(res.body.message);
      }
      if (typeof res.body.meta !== 'undefined') {
        if (typeof res.body.meta.total !== 'undefined' && typeof this.matPaginator !== 'undefined' && this.matPaginator !== null) {
          this.matPaginator.length = res.body.meta.total;
          this.length = res.body.meta.total;
        }
        if (typeof res.body.meta.current_page !== 'undefined' && typeof this.matPaginator !== 'undefined' && this.matPaginator !== null) {
          this.matPaginator.pageIndex = res.body.meta.current_page - 1;
        }
        if (typeof res.body.meta.per_page !== 'undefined') {
          this.pageSize = res.body.meta.per_page;
        }
      }

      return res.body;
    }
  }

  handleFileDownload(response: HttpResponse<Blob>, filename: string = null, mimeType: string = 'application/octet-stream'): ServerResponse {
    const header: string = response.headers.get('content-disposition'),
      responseFilename: string = header.split(';')[1].trim().split('=')[1].replace(/"/g, '');
    FileSaver.saveAs(
      new Blob([response.body], {type: mimeType}),
      (filename !== null ? filename : (responseFilename !== null ? responseFilename : null))
    );

    return {
      data: true
    };
  }

  handleErrorResponse(error: HttpErrorResponse): Observable<ServerResponse> {
    if (error.status === 401) {
      this.authenticationError.next(true);
      return of();
    } else {
      this.generalHttpError.next(true);
    }

    if (typeof error.error !== 'undefined' && error.error !== null && typeof error.error.message !== 'undefined') {
      this.snackbarService.error(error.error.message);
    } else {
      this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
    }

    if (typeof error.error !== 'undefined') {
      if (typeof error.error.errors !== 'undefined') {
        this.formService.processErrors(error.error.errors);
      }
    }

    throwError(error.error);

    const o = {
      status: 500,
      error: error.error,
      data: null
    };

    if (typeof error.error !== 'undefined' && typeof error.error.data !== 'undefined') {
      o.data = error.error.data;
    }

    return of(o);
  }

  isErrorResponse(response: ServerResponse): boolean {
    return !(typeof response.errors === 'undefined' && typeof response.exception === 'undefined' && response.status !== 500);
  }

  hasFilters(page: string, emitEvent: boolean = true): void {
    if (page) {
      const filters = this.localStorageService.getFilters(page),
        preSetFilters: FilterValue[] = [];

      if (filters) {
        if (!_.isEmpty(filters)) {
          _.each(filters, (value: string, key: string): void => {
            if (key !== 'pagination' && key !== 'sort') {
              const normalizedValue: string | number = (Number.isInteger(value) ? parseInt(value, 10) : value),
                index: number = preSetFilters.findIndex((d: FilterValue): boolean => d.name === key);

              this.setFilter(key, normalizedValue, false);

              if (index === -1) {
                preSetFilters.push({
                  name: key,
                  value: normalizedValue
                });
              }
            }
          });

          if (typeof filters.pagination !== 'undefined') {
            this.setPagination(filters.pagination, false);
            delete filters['pagination'];
          }
          if (typeof filters.sort !== 'undefined') {
            this.setSort(filters.sort, false);
            delete filters['sort'];
          }
        }
      }

      if (emitEvent) {
        this.preSetFilters.next(preSetFilters);
      }
      this.toggleResetTableButton();
    }
  }

  setFilter(field: string, value: string | number, emitEvent: boolean = true): void {
    const index: number = _.findIndex(this.routeParams.filters, data => data.field === field);
    this.localStorageService.preserveFilter(this.filterPageName, field, value);

    if (index !== -1) {
      this.routeParams.filters[index] = {
        field: field,
        value: value
      };
    } else {
      this.routeParams.filters.push({
        field: field,
        value: value
      });
    }
    if (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null) {
      this.matPaginator.pageIndex = 0;
    }
    this.routeParams.page = 1;

    if (emitEvent) {
      this.filterUpdated.next(true);
    }
  }

  clearFilter(field: string = null, emitEvent: boolean = true): void {
    if (field !== null) {
      const index: number = _.findIndex(this.routeParams.filters, data => data.field === field);

      if (index !== -1) {
        this.routeParams.filters.splice(index, 1);
      }

      this.localStorageService.removeFilter(this.filterPageName, field);
    } else {
      this.routeParams.filters = [];
    }

    if (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null) {
      this.matPaginator.pageIndex = 0;
    }
    this.routeParams.page = 1;

    if (emitEvent) {
      this.filterUpdated.next(true);
    }
  }

  setPagination(data: PageEvent | any, emitEvent: boolean = true): void {
    if (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null) {
      this.matPaginator.pageIndex = data.pageIndex;
      this.matPaginator.pageSize = data.pageSize;
      this.matPaginator.length = data.length;
    }
    this.routeParams.page = data.pageIndex + 1;
    this.routeParams.length = data.pageSize;

    this.localStorageService.preserveFilter(this.filterPageName, 'pagination', {
      pageIndex: data.pageIndex,
      pageSize: data.pageSize,
      length: data.length,
    });

    if (emitEvent) {
      this.filterUpdated.next(false);
    }
  }

  clearPagination(): void {
    if (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null) {
      this.matPaginator.pageIndex = 1;
      this.matPaginator.pageSize = this.pageSize;
      this.matPaginator.length = this.length;
    }

    this.routeParams.page = 1;
    this.routeParams.length = this.pageSize;
  }

  setSort(data: Sort, emitEvent: boolean = true): void {
    this.routeParams.active = (data.direction !== '' ? (typeof data.active !== 'undefined' && data.active !== '' ? data.active : '') : '');
    this.routeParams.direction = data.direction;

    if (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null && typeof this.matSort !== 'undefined' && this.matSort !== null) {
      if (this.matSort.active === null && data.active !== null && data.active !== '') {
        this.matSort.active = data.active;
        this.matSort.direction = data.direction;
        this.matSort._stateChanges.next();
      }
      if (this.matSort.active !== null && this.matSort.active !== data.active) {
        this.routeParams.page = 1;
        this.matPaginator.pageIndex = 0;
        this.localStorageService.preserveFilter(this.filterPageName, 'pagination', {
          pageIndex: 0,
          pageSize: this.pageSize,
          length: this.length,
        });
      }
    }

    this.localStorageService.preserveFilter(this.filterPageName, 'sort', {
      active: (typeof data.active !== 'undefined' && data.active !== '' ? data.active : ''),
      direction: data.direction
    });
    if (emitEvent) {
      this.filterUpdated.next(true);
    }
  }

  clearSort(emitEvent: boolean = true): void {
    if (typeof this.matSort !== 'undefined' && this.matSort !== null) {
      this.matSort.direction = 'asc';
      this.matSort.active = null;
      this.matSort._stateChanges.next();
    }
    this.routeParams.active = '';
    this.routeParams.direction = 'asc';
    this.routeParams.page = 1;
    this.routeParams.length = this.defaultPageSize;

    if (emitEvent) {
      this.filterUpdated.next(true);
    }
  }

  toggleResetTableButton(): void {
    let visible: boolean = false;
    if (typeof this.matSort !== 'undefined' && this.matSort !== null) {
      if (this.matSort.direction !== '' && this.matSort.direction !== 'asc' || typeof this.matSort.active !== 'undefined' && this.matSort.active !== null && this.matSort.active !== '') {
        visible = true;
      }
    }
    if (typeof this.matPaginator !== 'undefined' && this.matPaginator !== null) {
      if (this.matPaginator.pageIndex !== 0 && this.matPaginator.pageSize !== this.defaultPageSize) {
        visible = true;
      }
    }
    if (typeof this.routeParams.page !== 'undefined') {
      if (this.routeParams.page !== 1) {
        visible = true;
      }
    }
    if (typeof this.routeParams.filters !== 'undefined') {
      if (this.routeParams.filters.length) {
        visible = true;
      }
    }

    this.tableResetButtonVisible.next(visible);
  }

  processFormData(formData: any): FormData | null {
    const postData: FormData = new FormData();
    if (typeof formData !== 'undefined') {
      if (formData !== null) {
        Object.keys(formData).forEach((key: string): void => {
          if (this.ignoreFormFields.indexOf(key) === -1) {
            if (formData[key] instanceof FileInput) {
              for (let i: number = 0; i < formData[key]['_files'].length; i++) {
                postData.append(
                  key + (formData[key]['_files'].length > 1 ? '[]' : ''),
                  formData[key]['_files'][i], formData[key]['_files'][i].name
                );
              }
            } else if (formData[key] instanceof File) {
              if (typeof formData[key].length === 'undefined') {
                postData.append(key, formData[key], formData[key].name);
              } else {
                for (let i: number = 0; i < formData[key].length; i++) {
                  postData.append(
                    key + (formData[key].length > 1 ? '[]' : ''),
                    formData[key][i], formData[key][i].name
                  );
                }
              }
            } else if (Array.isArray(formData[key])) {
              for (let i: number = 0; i < formData[key].length; i++) {
                if (/*formData[key][i] !== false && */formData[key][i] !== 'undefined') {
                  if (formData[key][i] instanceof FileInput) {
                    for (let j: number = 0; j < formData[key][i][j]['_files'].length; j++) {
                      postData.append(
                        key + (formData[key]['_files'].length > 1 ? '[]' : ''),
                        formData[key]['_files'][i], formData[key]['_files'][i].name
                      );
                    }
                  } else if (formData[key][i] instanceof File) {
                    if (typeof formData[key][i].length === 'undefined') {
                      postData.append(key + '[]', formData[key][i], formData[key][i].name);
                    } else {
                      for (let j: number = 0; j < formData[key][i].length; j++) {
                        postData.append(
                          key + '[]',
                          formData[key][i][j], formData[key][i][j].name
                        );
                      }
                    }
                  } else if (formData[key][i] instanceof Object) {
                    Object.keys(formData[key][i]).map((d: string): void => {
                      postData.append(key + '[' + i + '][' + d + ']', formData[key][i][d]);
                    });
                  } else {
                    postData.append(key + '[' + i + ']', formData[key][i]);
                  }
                }
              }
            } else if (formData[key] instanceof Object) {
              Object.keys(formData[key]).forEach((key2: string): void => {
                if (/*formData[key][key2] !== false && */formData[key][key2] !== 'undefined') {
                  if (key2.indexOf('[') !== -1) {
                    postData.append(key + key2, formData[key][key2]);
                  } else {
                    if (Array.isArray(formData[key][key2])) {
                      formData[key][key2].forEach((d: any): void => {
                        postData.append(key + '[' + key2 + '][]', d);
                      });
                    } else {
                      postData.append(key + '[' + key2 + ']', formData[key][key2]);
                    }
                  }
                }
              });
            } else {
              postData.append(key, formData[key]);
            }
          }
        });

        return postData;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  notAvailableOffline(): void {
    this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
  }
}
