import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';
import { User } from '../../interfaces/routering/user';
import { Organisation } from '../../interfaces/routering/organisation';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from './api-endpoints.service';
import { HttpBackend, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as Sentry from '@sentry/browser';
import { ClientPortal } from '../../interfaces/configuration/client-portal';

@Injectable()

export class AuthenticationService {
  private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
  public user$: Observable<User> = this._user as Observable<User>;

  private _organisation: BehaviorSubject<Organisation> = new BehaviorSubject<Organisation>(null);
  public organisation$: Observable<Organisation> = this._organisation as Observable<Organisation>;

  private _clientPortal: BehaviorSubject<ClientPortal> = new BehaviorSubject<ClientPortal>(null);
  public clientPortal$: Observable<ClientPortal> = this._clientPortal as Observable<ClientPortal>;

  private clientPortalLogoSet: boolean = false;

  constructor(private api: ApiService,
              private httpBackend: HttpBackend,
              private apiEndpoints: ApiEndpointsService,
              private router: Router,
              private localStorageService: LocalStorageService,
  ) {
    this._organisation.next(this.localStorageService.get('organisation'));
    if (this.localStorageService.get('user') !== null) {
      this.getUserAccount().subscribe();
    } else {
      this._user.next(null);
    }
    this.getOrganisationConfig();

    this.user$.subscribe((user: User): void => {
      if (user) {
        Sentry.setUser({
          id: user.id,
          username: user.name,
          email: user.email,
        });
      } else {
        Sentry.setUser(null);
      }
    });

    this.api.authenticationError.subscribe((value: boolean): void => {
      if (value) {
        this.silentLogout();
      }
    });
  }

  updateAppColors(organisation: Organisation = null): void {
    document.documentElement.style.setProperty('--menu-background-color',
      (organisation !== null && typeof organisation.color_menu_background !== 'undefined' && organisation.color_menu_background !== ''
          ? organisation.color_menu_background
          : '#005192'
      )
    );
    document.documentElement.style.setProperty('--menu-text-color',
      (organisation !== null && typeof organisation.color_menu_text !== 'undefined' && organisation.color_menu_text !== ''
          ? organisation.color_menu_text
          : '#ffffff'
      )
    );
    document.documentElement.style.setProperty('--primary-color',
      (organisation !== null && typeof organisation.color_primary_action !== 'undefined' && organisation.color_primary_action !== ''
          ? organisation.color_primary_action
          : '#1b143c'
      )
    );
    document.documentElement.style.setProperty('--primary-color-text',
      (organisation !== null && typeof organisation.color_primary_action_text !== 'undefined' && organisation.color_primary_action_text !== ''
          ? organisation.color_primary_action_text
          : '#ffffff'
      )
    );
    document.documentElement.style.setProperty('--secondary-color',
      (organisation !== null && typeof organisation.color_secondary_action !== 'undefined' && organisation.color_secondary_action !== ''
          ? organisation.color_secondary_action
          : '#666666'
      )
    );
    document.documentElement.style.setProperty('--secondary-color-text',
      (organisation !== null && typeof organisation.color_secondary_action_text !== 'undefined' && organisation.color_secondary_action_text !== ''
          ? organisation.color_secondary_action_text
          : '#ffffff'
      )
    );
    if (document.getElementById('logo') && !this.clientPortalLogoSet) {
      document.getElementById('logo').setAttribute('src',
        (organisation !== null && typeof organisation.logo !== 'undefined' && organisation.logo !== ''
            ? organisation.logo
            : 'assets/img/logo.svg'
        )
      );
    }
  }

  getOrganisationConfig(): void {
    const defaultOrganisation = this.localStorageService.get('default-organisation'),
      organisation = this.localStorageService.get('organisation');

    if (organisation === null) {
      if (defaultOrganisation === null) {
        this.getDefaultOrganisation();
      } else {
        this.updateAppColors(defaultOrganisation);
      }
    } else if (organisation) {
      this.updateAppColors(organisation);
    } else {
      this.updateAppColors();
    }
  }

  getDefaultOrganisation(): void {
    const httpApi: HttpClient = new HttpClient(this.httpBackend);
    httpApi.get(environment.api_endpoint + this.apiEndpoints.get('public.default-organisation'), {
      observe: 'response',
      responseType: 'json',
    })
      .subscribe(
        (response: HttpResponse<ServerResponse>): void => {
          if (typeof response.body.data !== 'undefined') {
            this.localStorageService.set('default-organisation', <Organisation>response.body.data);
            this.updateAppColors(<Organisation>response.body.data);
          }
        },
        (): void => this.updateAppColors()
      );
  }

  login(formData: any): Promise<boolean> {
    return this.api.post(`auth/login`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            if (typeof response.data.user.clientPortal !== 'undefined') {
              this.localStorageService.set('client-portal', response.data.user.clientPortal);
              this._clientPortal.next(response.data.user.clientPortal);
            }

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          } else if (typeof response.data.two_factor_login !== 'undefined') {
            this.localStorageService.set('2fa-token', response.data.token);
            this.localStorageService.set('2fa-id', response.data.id);
            this.router.navigate(['/auth/login/two-factor-authentication']).then((): void => {
            });
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  loginTwoFactorCode(formData: any): Promise<boolean> {
    formData.id = this.localStorageService.get('2fa-id');
    formData.token = this.localStorageService.get('2fa-token');
    return this.api.post(`auth/login/two-factor-code`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);
            this.localStorageService.delete('2fa-token');
            this.localStorageService.delete('2fa-id');

            if (typeof response.data.user.clientPortal !== 'undefined') {
              this.localStorageService.set('client-portal', response.data.user.clientPortal);
              this._clientPortal.next(response.data.user.clientPortal);
            }

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  loginTwoFactorRecoveryCode(formData: any): Promise<boolean> {
    formData.id = this.localStorageService.get('2fa-id');
    formData.token = this.localStorageService.get('2fa-token');
    return this.api.post(`auth/login/two-factor-code`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);
            this.localStorageService.delete('2fa-token');
            this.localStorageService.delete('2fa-id');

            if (typeof response.data.user.clientPortal !== 'undefined') {
              this.localStorageService.set('client-portal', response.data.user.clientPortal);
              this._clientPortal.next(response.data.user.clientPortal);
            }

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  logout(): Subscription {
    return this.api.get(`auth/logout`).subscribe((): void => {
      this.silentLogout();
    });
  }

  silentLogout(): void {
    this.localStorageService.delete('api-token');
    this.localStorageService.delete('user');
    this.localStorageService.delete('client-portal');

    this._user.next(null);

    this.router.navigate(['/auth/login']).then((): boolean => true);
  }

  validateCompleteAccountToken(id: number, expires: string, signature: string): Observable<ServerResponse> {
    return this.api.get(`auth/complete-account/${id}?expires=${expires}&signature=${signature}`, null, true);
  }

  completeAccount(id: number, expires: string, signature: string, formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/complete-account/${id}?expires=${expires}&signature=${signature}`, formData);
  }

  forgotPassword(formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/forgot-password`, formData);
  }

  validateResetPasswordToken(token: string, email: string): Observable<ServerResponse> {
    return this.api.get(`auth/reset-password/${token}?email=${email}`, null, true);
  }

  resetPassword(formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/reset-password`, formData);
  }

  changePassword(formData: any): Observable<ServerResponse> {
    return this.api.post(`auth/change-password`, formData);
  }

  getUserAccount(): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.get(`auth/account`).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            this.localStorageService.set('user', response.data);
            this._user.next(response.data);

            if (typeof response.data.clientPortal !== 'undefined') {
              this.localStorageService.set('client-portal', response.data.clientPortal);
              this._clientPortal.next(response.data.clientPortal);
            }

            o.next(true);
          } else {
            this._user.next(null);

            o.next(false);
          }
        },
        (): void => {
          this._user.next(null);
          o.next(false);
        }
      );

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  getAccount(): Observable<ServerResponse> {
    return this.api.get(`auth/account`, null, true);
  }

  updateAccount(formData: any): Promise<boolean> {
    return this.api.post(`auth/account`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (!this.api.isErrorResponse(response)) {
          if (typeof response.data !== 'undefined') {
            this.localStorageService.set('user', response.data);
            this._user.next(response.data);

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          }

          return false;
        } else {
          return false;
        }
      })
      .catch((): boolean => false);
  }

  getTwoFactorAuthEnableData(): Observable<ServerResponse> {
    return this.api.get('auth/two-factor-authentication/enable-data');
  }

  confirmTwoFactorAuthEnableData(formData: any): Observable<ServerResponse> {
    return this.api.post('auth/two-factor-authentication/confirm', formData);
  }

  getRecoveryCodes(formData: any): Observable<ServerResponse> {
    return this.api.post('auth/two-factor-authentication/recovery-codes', formData);
  }

  regenerateRecoveryCodes(formData: any): Observable<ServerResponse> {
    return this.api.post('auth/two-factor-authentication/regenerate-recovery-codes', formData);
  }

  startImpersonating(formData): Promise<boolean> {
    return this.api.post(`auth/impersonate`, formData).toPromise()
      .then((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            if (typeof response.data.user.organisation !== 'undefined') {
              this.localStorageService.set('organisation', response.data.user.organisation);
              this._organisation.next(response.data.user.organisation);
            } else {
              this.localStorageService.delete('organisation');
              this._organisation.next(null);
            }

            if (typeof response.data.user.clientPortal !== 'undefined') {
              this.localStorageService.set('client-portal', response.data.user.clientPortal);
              this._clientPortal.next(response.data.user.clientPortal);
            }

            this.getOrganisationConfig();

            this.router.navigate(['/']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  stopImpersonating(): Promise<boolean> {
    return this.api.delete(`auth/impersonate`, false).toPromise()
      .then
      ((response: ServerResponse): boolean => {
        if (typeof response.data !== 'undefined') {
          if (typeof response.data.token !== 'undefined' && typeof response.data.user !== 'undefined') {
            this.localStorageService.set('api-token', response.data.token);
            this.localStorageService.set('user', response.data.user);
            this._user.next(response.data.user);

            if (typeof response.data.user.organisation !== 'undefined') {
              this.localStorageService.set('organisation', response.data.user.organisation);
              this._organisation.next(response.data.user.organisation);
            } else {
              this.localStorageService.delete('organisation');
              this._organisation.next(null);
            }

            this.localStorageService.delete('client-portal');
            this._clientPortal.next(null);

            this.getOrganisationConfig();

            this.router.navigate(['/klanten']).then((): void => {
            });

            return true;
          }
        }

        return false;
      })
      .catch((): boolean => false);
  }

  resendInvitation(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('users.resend-invitation', {':id': id}));
  }
}
