import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Injectable()

export class FormService {

  public form: FormGroup = null;
  private ignoreFieldsWhenPopulating: Array<string> = [
    'internal_note',
    'internal_note_boa',
    'categories',
    'options',
  ];
  private fileInputs: Array<string> = [
    'logo',
    'logo_email',
    'icon',
    'attachments',
  ];

  constructor() {
  }

  populateForm(data, emitEvents: string[] = null): void {
    if (typeof this.form !== 'undefined') {
      if (typeof this.form.controls !== 'undefined') {
        if (typeof data !== 'undefined') {
          Object.keys(this.form.controls).map((controlKey: string): void => {
            if (this.ignoreFieldsWhenPopulating.indexOf(controlKey) === -1) {
              if (typeof data[controlKey] === 'object') {
                if (typeof this.form.controls[controlKey] !== 'undefined') {
                  if (typeof this.form.controls[controlKey]['controls'] !== 'undefined') {
                    if (data[controlKey] !== null) {
                      Object.keys(this.form.controls[controlKey]['controls']).map((controlKey2: string): void => {
                        if (this.ignoreFieldsWhenPopulating.indexOf(controlKey2) === -1) {
                          if (typeof this.form.controls[controlKey]['controls'][controlKey2] !== 'undefined' && typeof data[controlKey][controlKey2] !== 'undefined') {
                            if (this.fileInputs.indexOf(controlKey) === -1 && this.fileInputs.indexOf(controlKey2) === -1 && data[controlKey][controlKey2] !== '' && data[controlKey][controlKey2] !== null) {
                              this.form.controls[controlKey]['controls'][controlKey2].setValue(data[controlKey][controlKey2], {emitEvent: ((emitEvents && emitEvents.indexOf(controlKey2) !== -1) ?? false)});
                            }
                          }
                        }
                      });
                    }
                  }
                }
              } else {
                if (typeof this.form.controls[controlKey] !== 'undefined' && typeof data[controlKey] !== 'undefined') {
                  if (this.fileInputs.indexOf(controlKey) === -1 && data[controlKey] !== '' && data[controlKey] !== null) {
                    this.form.controls[controlKey].setValue(data[controlKey], {emitEvent: ((emitEvents && emitEvents.indexOf(controlKey) !== -1) ?? false)});
                  }
                }
              }
            }
          });
        }
      }
    }
  }

  processErrors(responseErrors: any): void {
    _.forEach(responseErrors, (errors, field: string): void => {
      const control = this.findFieldControl(field);
      const fieldErrors: string[] = this.fetchFieldErrors(errors, field);
      control.setErrors(fieldErrors);
    });
  }

  findFieldControl(field: string): AbstractControl {
    let control: AbstractControl;
    if (field === 'base') {
      control = this.form;
    } else if (this.form.contains(field)) {
      control = this.form.get(field);
    } else if (field.match(/_id$/) && this.form.contains(field.substring(0, field.length - 3))) {
      control = this.form.get(field.substring(0, field.length - 3));
    } else if (field.indexOf('.') > 0) {
      let group = this.form;
      field.split('.').forEach((f) => {
        if (group.contains(f)) {
          control = group.get(f);
          if (control instanceof FormGroup) {
            group = control;
          }
        } else {
          control = group;
        }
      });
    } else {
      // Field is not defined in form but there is a validation error for it, set it globally
      control = this.form;
    }
    return control;
  }

  fetchFieldErrors(data: any, field: string): string[] | null {
    const errors: string[] = [];

    data.forEach(error => {
      errors.push(error);
    });

    return errors;
  }

  getFieldError(field: string): string | boolean {
    const control = this.findFieldControl(field);
    let error = null;
    if (typeof control !== 'undefined') {
      if (typeof control.errors !== 'undefined') {
        if (control.errors !== null) {
          Object.keys(control.errors).map((key: any): void => {
            if (key === 'maxLengthError') {
              error = 'Max. toegestane karakters ' + control.errors[key].maxLength;
            } else if (key === 'minlength') {
              error = 'Min. toegestane karakters ' + control.errors[key].requiredLength;
            } else if (key !== 'required' && key !== 'email') {
              error = control.errors[key];
            }
          });
        }
      }
    }
    return error;
  }
}
