import { Injectable } from '@angular/core';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { Report } from '../../interfaces/report/report';
import { MenuReportCounts } from '../../interfaces/menu-report-counts';

@Injectable()

export class ReportService {

  public sourceUpdated: Subject<boolean> = new Subject<boolean>();
  public lastUpdatedReportId: number = null;
  public reportDeletedId: Subject<number> = new Subject<number>();

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.index'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<ServerResponse>response);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  count(): Observable<MenuReportCounts | boolean> {
    return new Observable<MenuReportCounts | boolean>((o: Subscriber<MenuReportCounts | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.count'), null, true)
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MenuReportCounts>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(id: number): Observable<Report | boolean> {
    return new Observable<Report | boolean>((o: Subscriber<Report | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('reports.read', {':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Report>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  downloadAttachment(id: number, attachment_id: number): void {
    this.api.download(this.apiEndpoints.get('reports.download-attachment', {':id': id, ':attachment_id': attachment_id}));
  }

  downloadHandlingAttachment(id: number, attachment_id: number): void {
    this.api.download(this.apiEndpoints.get('reports.download-handling-attachment', {':id': id, ':attachment_id': attachment_id}));
  }

  history(id: number): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('reports.history', {':id': id}));
  }

  export(formData: any, fileType: string = 'csv'): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('reports.export', {':fileType': fileType}), formData);
  }
}
