import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthorizationService } from './services/base/authorization.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthorizationService],
    canActivateChild: [AuthorizationService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/meldingen'
      },
      {
        path: 'meldingen',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: {
          permissions: []
        }
      },
      {
        path: 'gebruikers',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        data: {
          permissions: []
        }
      },
      {
        path: 'bedrijven',
        loadChildren: () => import('./pages/companies/companies.module').then(m => m.CompaniesModule),
        data: {
          permissions: []
        }
      },
      {
        path: 'handleiding',
        loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
      },
      {
        path: 'kaart-weergave',
        loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule),
        data: {
          title: 'Kaart weergave',
          permissions: [],
        }
      },
      {
        path: 'rapportages',
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
        data: {
          permissions: []
        }
      },
      {
        path: 'klanten',
        loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule),
        data: {
          permissions: []
        }
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/meldingen'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
//    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
