import { ChangeDetectorRef, Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

@Injectable()

export class DateTimeService {
  public cd: ChangeDetectorRef = null;

  constructor() {
  }

  fixDateTimeManualInput(control: FormControl, $event: any): void {
    if (control !== null && $event.value !== null) {
      // noinspection JSUnresolvedReference
      if (typeof $event.value._i !== 'undefined' && typeof $event.value._i === 'string') {
        // noinspection JSUnresolvedReference
        control.setValue(moment($event.value._i, 'DD-MM-YY HH:mm').toISOString());
      } else if (typeof $event.value._d !== 'undefined') {
        // noinspection JSUnresolvedReference
        control.setValue(moment($event.value._d, 'DD-MM-YY HH:mm').toISOString());
      } else {
        throw new Error('Invalid date provided: ' + $event.value);
      }

      control.updateValueAndValidity();
    }

    if (this.cd !== null) {
      console.log('a');
      this.cd.detectChanges();
    }
  }
}
