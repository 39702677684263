import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { ApiEndpointsService } from './api-endpoints.service';
import { UserAgreement } from '../../interfaces/base/user-agreement';
import { ServerResponse } from '../../interfaces/base/server.response';

@Injectable()

export class UserAgreementService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  get(): Observable<UserAgreement | boolean> {
    return new Observable<UserAgreement | boolean>((o: Subscriber<UserAgreement | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('auth.user-agreement'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<UserAgreement>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  confirm(id: number): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('auth.user-agreement-confirm', {':id': id}));
  }
}
