import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { Platform } from '@angular/cdk/platform';
import { SplashScreenService } from '../@fury/services/splash-screen.service';
import { AuthenticationService } from './services/base/authentication.service';
import { User } from './interfaces/routering/user';
import { version } from '../environments/version';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private sidenavService: SidenavService,
              private iconRegistry: MatIconRegistry,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private platform: Platform,
              private splashScreenService: SplashScreenService, // required!! otherwise splashScreen does not go away on app load
              private authenticationService: AuthenticationService,
  ) {
    this.renderer.addClass(this.document.body, 'fury-default');
    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');
    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.authenticationService.user$.subscribe((user: User): void => {
      if (user) {
        if (typeof window.Marker !== 'undefined') {
          window.markerConfig.reporter = {
            email: user.email,
            fullName: user.name,
          };

          window.Marker.setCustomData({
            version: version
          });
        }

        this.sidenavService.items = [];
        this.sidenavService.itemsBottom = [];

        if (user.two_factor_enabled) {
          this.sidenavService.addItems([
            {
              name: 'Meldingen',
              routeOrFunction: '/meldingen',
              icon: 'assignment',
              position: 10,
              badgeItem: 'reports_all',
              alwaysOpened: false,
              permissions: [],
            },
            {
              name: 'Kaart weergave',
              routeOrFunction: '/kaart-weergave',
              icon: 'map',
              position: 20,
              alwaysOpened: false,
              permissions: [],
            },
/*
            {
              name: 'Bedrijven',
              routeOrFunction: '/bedrijven',
              icon: 'business',
              position: 30,
              alwaysOpened: false,
              permissions: [],
            },
*/
            {
              name: 'Rapportages',
              routeOrFunction: '/rapportages',
              icon: 'insert_chart',
              position: 40,
              alwaysOpened: false,
              permissions: [],
            },
          ]);
          if (user.is_main_contact) {
            this.sidenavService.addItems([
              {
                name: 'Gebruikers',
                routeOrFunction: '/gebruikers',
                icon: 'group',
                position: 50,
                alwaysOpened: false,
                permissions: [],
              },
            ]);
          }

          this.sidenavService.addItemsBottom([
            {
              name: 'Help & support',
              routeOrFunction: '/handleiding',
              icon: 'help',
              position: 10
            },
          ]);
        }
      }
    });
  }
}
