import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()

export class ApiEndpointsService {

  private endpoints: { key: string, route: string }[] | [] = [
    {key: 'public.default-organisation', route: 'v1/public/default-organisation'},

    {key: 'auth.user-agreement', route: 'auth/user-agreement'},
    {key: 'auth.user-agreement-confirm', route: 'auth/user-agreement/:id'},

    {key: 'filters.organisations', route: 'v1/filters/organisations'},
    {key: 'filters.organisations-for-active-user', route: 'v1/filters/organisations/for-active-user'},
    {key: 'filters.departments', route: 'v1/filters/departments'},
    {key: 'filters.organisation-departments', route: 'v1/filters/organisations/:id/departments'},
    {key: 'filters.organisation-users', route: 'v1/filters/organisations/:id/users'},
    {key: 'filters.organisation-department-users', route: 'v1/filters/organisations/:organisation_id/departments/:department_id/users'},
    {key: 'filters.assigned-organisation-departments', route: 'v1/filters/organisation/departments'},
    {key: 'filters.assigned-organisation-users', route: 'v1/filters/organisation/users'},
    {key: 'filters.assigned-organisation-department-users', route: 'v1/filters/organisation/departments/:department_id/users'},
    {key: 'filters.organisations-with-departments', route: 'v1/filters/organisations/departments'},
    {key: 'filters.permissions', route: 'v1/filters/permissions'},
    {key: 'filters.permission-groups', route: 'v1/filters/permissions/groups'},
    {key: 'filters.roles', route: 'v1/filters/roles'},
    {key: 'filters.mail-types', route: 'v1/filters/mail-types'},
    {key: 'filters.provinces', route: 'v1/filters/provinces'},
    {key: 'filters.available-municipalities', route: 'v1/filters/municipalities/available'},
    {key: 'filters.active-province-municipalities', route: 'v1/filters/provinces/municipalities'},
    {key: 'filters.municipalities', route: 'v1/filters/municipalities'},
    {key: 'filters.municipalities-and-provinces', route: 'v1/filters/municipalities-and-provinces'},
    {key: 'filters.cities', route: 'v1/filters/cities'},
    {key: 'filters.city', route: 'v1/filters/cities/:city_id'},
    {key: 'filters.report-products', route: 'v1/filters/report-products'},
    {key: 'filters.report-statuses', route: 'v1/filters/report-statuses'},
    {key: 'filters.report-history-types', route: 'v1/filters/report-history-types'},
    {key: 'filters.report-causer-types', route: 'v1/filters/report-causer-types'},
    {key: 'filters.reporter-types', route: 'v1/filters/reporter-types'},
    {key: 'filters.reporter-contact-timeframes', route: 'v1/filters/reporter-contact-timeframes'},
    {key: 'filters.users', route: 'v1/filters/users'},
    {key: 'filters.risks', route: 'v1/filters/risks'},
    {key: 'filters.head-categories-types', route: 'v1/filters/head-categories-types'},
    {key: 'filters.head-categories', route: 'v1/filters/head-categories'},
    {key: 'filters.head-categories-for-surrounding', route: 'v1/filters/head-categories/surrounding/:id'},
    {key: 'filters.head-categories.main-categories', route: 'v1/filters/head-categories/:head_category_id/main-categories'},
    {
      key: 'filters.head-categories.main-categories-for-surrounding',
      route: 'v1/filters/head-categories/:head_category_id/main-categories/surrounding/:id'
    },
    {
      key: 'filters.head-categories.main-categories.sub-categories',
      route: 'v1/filters/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories'
    },
    {key: 'filters.main-categories-surroundings', route: 'v1/filters/main-categories-surroundings'},
    {key: 'filters.main-categories', route: 'v1/filters/main-categories'},
    {key: 'filters.report-sources', route: 'v1/filters/report-sources'},
    {key: 'filters.company-options', route: 'v1/filters/company-options'},
    {key: 'filters.report-status-codes', route: 'v1/filters/report-status-codes'},
    {key: 'filters.report-status-codes-for', route: 'v1/filters/report-status-codes/:for'},
    {key: 'filters.address-book', route: 'v1/filters/address-book'},
    {key: 'filters.standard-reactions', route: 'v1/filters/standard-reactions'},
    {key: 'filters.report-history', route: 'v1/filters/report-history'},
    {key: 'filters.address-book-types', route: 'v1/filters/address-book-types'},
    {key: 'filters.reporters', route: 'v1/filters/reporters'},
    {key: 'filters.unusual-incident-causes', route: 'v1/filters/unusual-incident-causes'},
    {key: 'filters.unusual-incident-consequences', route: 'v1/filters/unusual-incident-consequences'},
    {key: 'filters.knowledge-base.changelogs.types', route: 'v1/filters/knowledge-base/changelogs/types'},
    {key: 'filters.public-notification-types', route: 'v1/filters/public-notification-types'},

    {key: 'filters.companies', route: 'v1/client-portal/companies/search'},
    {key: 'filters.companies-with-types', route: 'v1/client-portal/companies/with-types'},

    {key: 'overview', route: 'v1/client-portal/overview'},
    {key: 'companies.index', route: 'v1/client-portal/companies'},

    {key: 'clients.index', route: 'v1/client-portal/clients'},
    {key: 'clients.read', route: 'v1/client-portal/clients/:id'},
    {key: 'clients.users.index', route: 'v1/client-portal/clients/:id/users'},

    {key: 'reports.index', route: 'v1/client-portal/reports'},
    {key: 'reports.index-download', route: 'v1/client-portal/reports/export'},
    {key: 'reports.count', route: 'v1/client-portal/reports/count'},
    {key: 'reports.read', route: 'v1/client-portal/reports/:id'},
    {key: 'reports.download-attachment', route: 'v1/client-portal/reports/:id/attachments/:attachment_id'},
    {key: 'reports.download-handling-attachment', route: 'v1/client-portal/reports/:id/handling/attachments/:attachment_id'},
    {key: 'reports.export', route: 'v1/client-portal/reports/export/:fileType'},
    {key: 'reports.history', route: 'v1/client-portal/reports/:id/history'},

    {key: 'knowledge-base.contact-details.index', route: 'v1/knowledge-base/support-contact-details'},
    {key: 'knowledge-base.faq.index', route: 'v1/knowledge-base/faq'},
    {key: 'knowledge-base.support-request', route: 'v1/knowledge-base/support-request'},
    {key: 'knowledge-base.kb.index', route: 'v1/knowledge-base'},
    {key: 'knowledge-base.kb.read', route: 'v1/knowledge-base/:url'},
    {key: 'knowledge-base.kb.articles', route: 'v1/knowledge-base/:articles/articles'},
    {key: 'knowledge-base.kb.article', route: 'v1/knowledge-base/:articles/articles/:article'},

    {key: 'knowledge-base.changelogs.index', route: 'v1/knowledge-base/changelogs'},
    {key: 'knowledge-base.changelogs.read', route: 'v1/knowledge-base/changelogs/:url'},
    {key: 'knowledge-base.changelogs.articles', route: 'v1/knowledge-base/changelogs/:changelog_url/articles'},

    {key: 'knowledge-base.organisations', route: 'v1/knowledge-base/organisations'},

    {key: 'statistics.categories-per-municipality', route: 'v1/client-portal/statistics/categories-per-municipality'},
    {key: 'statistics.categories-per-municipality-download', route: 'v1/client-portal/statistics/categories-per-municipality/export'},
    {key: 'statistics.complaints-per-company', route: 'v1/client-portal/statistics/complaints-per-company'},
    {key: 'statistics.complaints-per-company-download', route: 'v1/client-portal/statistics/complaints-per-company/export'},
    {key: 'statistics.total-complaints-per-company', route: 'v1/client-portal/statistics/total-complaints-per-company'},
    {key: 'statistics.total-complaints-per-company-download', route: 'v1/client-portal/statistics/total-complaints-per-company/export'},
    {key: 'statistics.total-complaints-per-company.for-company.index', route: 'v1/client-portal/statistics/total-complaints-per-company/:id'},
    {key: 'statistics.total-complaints-per-company.for-company.download', route: 'v1/client-portal/statistics/total-complaints-per-company/:id/export'},

    {key: 'users.index', route: 'v1/client-portal/users'},
    {key: 'users.create', route: 'v1/client-portal/users'},
    {key: 'users.read', route: 'v1/client-portal/users/:id'},
    {key: 'users.update', route: 'v1/client-portal/users/:id'},
    {key: 'users.resend-invitation', route: 'v1/client-portal/users/:id/resend-invitation'},
    {key: 'users.remove-2fa-code', route: 'v1/client-portal/users/:id/remove-2fa-code'},
    {key: 'users.delete', route: 'v1/client-portal/users/:id'},
  ];

  constructor() {
  }

  get(name: string, params?): string | null {
    const index: number = _.findIndex(this.endpoints, d => d.key === name);

    if (index !== -1) {
      if (params) {
        const url: string = this.endpoints[index].route;

        return url.replace(/(:[a-zA-Z_]+)/g, m => {
          return params[m];
        });
      } else {
        return this.endpoints[index].route;
      }
    }

    throw new Error('Unknown route URL requested: ' + name);
  }

  /*
    getCustomUrl(url: string, params?): string | null {
      return url.replace(/(:[a-zA-Z_]+)/g, m => {
        return params[m];
      });
    }
  */
}
